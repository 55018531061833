// Components/TermsAndConditionsModal.js
import React from "react";
import "./TermsAndConditionsModal.css"; 

const TermsAndConditionsModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
        <i className="fas fa-times"></i>
        </button>
        <div
          className="modal-content-inner"
          style={{ background: "white", padding: "20px" }}
        >
          <h2>Términos y Condiciones</h2>
          <p>Al utilizar este servicio, usted garantiza que posee todos los derechos necesarios sobre el contenido publicado y nos autoriza a utilizarlo de acuerdo con estos términos.</p>
          <p><strong>Uso de Datos:</strong> La empresa se compromete a no utilizar las fotos proporcionadas para difusión. No nos hacemos responsables del mal uso que los usuarios puedan hacer del contenido. Al compartir fotos en este servicio, usted reconoce que son de dominio público y acepta que cualquier uso que hagan los usuarios de estos datos es responsabilidad de ellos mismos.

</p>
          <p>Nos reservamos el derecho de eliminar cualquier contenido que infrinja estas condiciones.</p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsModal;
