// src/components/PaginationControls.js
import React from "react";
import "./PaginationControls.css"; 
const PaginationControls = ({ currentPage, totalPages, onPageChange, disablePrev, disableNext }) => {

// const PaginationControls = ({ currentPage, totalPages, onPageChange }) => {
  const radius = 18;
  const circumference = 2 * Math.PI * radius;
  
   // Ensure totalPages is at least 1
   const validTotalPages = Math.max(1, totalPages);
   const progress = (currentPage / validTotalPages) * circumference;

  return (
    <div className="pagination-controls">
      <button
        onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
        disabled={currentPage === 1 || totalPages === 0}
        className="pagination-button"
      >
        <i className="fas fa-chevron-left"></i>
      </button>

    {/* Circular Progress Bar */}
      <svg className="pagination-progress-circle" width="50" height="50">
        <circle
          cx="25"
          cy="25"
          r={radius}
          fill="none"
          stroke="#e0e0e0"
          strokeWidth="3"
        />
        <circle
          cx="25"
          cy="25"
          r={radius}
          fill="none"
          stroke="#dcc8b4"
          strokeWidth="3"
          strokeDasharray={circumference}
          strokeDashoffset={circumference - progress}
        />
        <text x="50%" y="50%" textAnchor="middle" dy=".3em" fontSize="12px">
          {currentPage}/{validTotalPages}
        </text>
      </svg>


      <button
        onClick={() => onPageChange(Math.min(currentPage + 1, totalPages))}
        disabled={currentPage === totalPages || totalPages === 0}
        className="pagination-button"
      >
        <i className="fas fa-chevron-right"></i>
      </button>
    </div>
  );
};

export default PaginationControls;
