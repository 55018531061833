import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './assets/styles/Admin.css';
import './App.css';

const Admin = () => {
  const [password, setPassword] = useState(''); // For storing user input
  const [isAuthenticated, setIsAuthenticated] = useState(false); // For tracking if user is authenticated

  const correctPassword = 'Admin123'; // Define your password here

  const handleLogin = (event) => {
    event.preventDefault();
    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password');
    }
  };

  if (!isAuthenticated) {
    // Render the password input form if the user is not authenticated
    return (
      <div className="admin-container">
        <h2>Admin Access</h2>
        <form onSubmit={handleLogin} className="password-form">
          <label htmlFor="password">Enter Admin Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit" className="submit-button">Login</button>
        </form>
      </div>
    );
  }

  // If the user is authenticated, show the admin dashboard
  return (
    <div className="admin-container">
      <h2>Admin Dashboard</h2>
      <div className="admin-links">
        <Link to="/admin/create" className="admin-link">
          Crear Nuevo Evento
        </Link>
        <Link to="/admin/list-events" className="admin-link">
          Lista de Eventos
        </Link>
      </div>
    </div>
  );
};

export default Admin;
