import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  API_POST_PHOTOGRAPHER,
  API_GET_CHECK_IF_EVENT_EXISTS,
} from "./endpoints";
import { handleFileChange } from "./utils/fileUtilsPhotographer"; // Import the new file handling function
import "./assets/styles/photograph-upload.css";
// import "./App.css";
import Header from "./Components/Header/Header";
import { Helmet } from "react-helmet";

const PhotographUpload = () => {
  const [files, setFiles] = useState([]); // State to hold the selected files
  const [eventId, setEventId] = useState("");
  const [uploaderName, setUploaderName] = useState("");
  const [photoMessage, setPhotoMessage] = useState("");
  const [uploadUrls, setUploadUrls] = useState([]); // To store uploaded file URLs
  const [errorMessage, setErrorMessage] = useState("");
  const [eventExists, setEventExists] = useState(true); // New state to check if the event exists
  const [loading, setLoading] = useState(false); // State to manage loading
  const [fileProcessing, setFileProcessing] = useState(false); // New state to manage file processing

  const fileInputRef = useRef(null); // Create a ref for the file input

  // Handle file selection and compression
  const handleFileSelection = (event) => {
    const selectedFiles = Array.from(event.target.files);
    console.log("Selected files:", selectedFiles); // Log selected files for debugging
    // setFiles(selectedFiles); // Update state with the selected files
    setFileProcessing(true); // Indicate that file processing is starting

    // handleFileChange(event, setFiles); // Use the utility function to handle file changes
  //   handleFileChange(event, (updatedFiles) => {
  //     setFiles(updatedFiles); // Use callback to update state
  //     console.log("Updated files:", updatedFiles); // Log updated files
  // });
  handleFileChange(event, (updatedFiles) => {
    setFiles(updatedFiles); // Use callback to update state
    setFileProcessing(false); // Indicate that file processing is done

    console.log("Updated files:", updatedFiles.length); // Check if files are updated correctly
  });
  };

   // Ensure state is updated after file selection
   useEffect(() => {
    console.log("Files state updated:", files); // Logs whenever files state changes
  }, [files]);

  // Handle changes in the photo message textarea
  const handlePhotoMessageChange = (e) => {
    const inputText = e.target.value;
    const wordCount = inputText.trim().split(/\s+/).length;

    if (wordCount > 8) {
      setErrorMessage("El mensaje no debe tener más de 8 palabras.");
    } else {
      setErrorMessage(""); // Clear error message if valid
      setPhotoMessage(inputText);
    }
  };

  const formatTimestamp = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Event ID before upload:", eventId); 
    setLoading(true); // Start the loading state
    console.log("Files before submission:", files); // Log files before submission

    if (files.length === 0) {
      setErrorMessage("Por favor, seleccioná al menos una foto.");
      return;
    }
    // Check if photoMessage exceeds 8 words before proceeding
    const photoWordCount = photoMessage.trim().split(/\s+/).length;
    if (photoWordCount > 8) {
      setErrorMessage("El mensaje no debe tener más de 8 palabras.");
      return;
    }

    // Check if the event exists
    try {
      const checkEventResponse = await axios.post(
        API_GET_CHECK_IF_EVENT_EXISTS,
        {
          eventId,
        }
      );

      if (!checkEventResponse.data.exists) {
        setErrorMessage("El evento con este ID no existe.");
        setLoading(false);
        return;
      }
    } catch (error) {
      console.error("Error checking the event ID:", error);
      setErrorMessage("Hubo un error al verificar el ID del evento.");
      setLoading(false);
      return;
    }
    // If there's an error message, block the upload
    if (errorMessage) {
      return;
    }

    try {
      // Iterate through the files and upload each one
      const urls = await Promise.all(
        files.map(async (file) => {
          // Step 1: Get the signed URL
          const timestamp = formatTimestamp(); // Get the timestamp
          const fileNameWithTimestamp = `photoUpload_${timestamp}_${file.name}`; // Generate unique file name

          const response = await axios.post(API_POST_PHOTOGRAPHER, {
            fileName: fileNameWithTimestamp,
            fileType: file.type,
            eventId,
            uploaderName,
            photoMessage,
            isPhotographer: true
          });

          const { uploadUrl } = response.data;

          // Step 2: Upload the file
          await axios.put(uploadUrl, file, {
            headers: {
              "Content-Type": file.type,
            }

          });

          return response.data.metadata.photoUrl; // Return the uploaded file URL
        })
      );



      // Step 3: Store the URLs in state and reset the form
      setUploadUrls(urls);
      setFiles([]); // Clear the files

 // Clear the file input programmatically
 if (fileInputRef.current) {
  fileInputRef.current.value = "";
}


      // setEventId("");
      setUploaderName("");
      setPhotoMessage("");
      setErrorMessage("");
      // Reset the file input
      document.querySelector('input[type="file"]').value = "";
    } catch (error) {
      console.error("Error uploading the files:", error);
      setErrorMessage(
        "Hubo un error al subir las fotos. Por favor, intentá de nuevo."
      );
    }
    setLoading(false); // End loading state
  };

  return (
    <div className="photograph-upload-container">
      <Helmet>
        <title>Snap Moments Portal Fotógrafos</title>
      </Helmet>
      <Link to="/">
        <Header />
      </Link>
      <h2>Portal Fotógrafo</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            ID del Evento:
            <input
              type="text"
              value={eventId}
              onChange={(e) => setEventId(e.target.value)}
              required
              placeholder="demo-boda"
            />
          </label>
        </div>
        <div>
          <label>
            Tu nombre (requerido)
            <input
              type="text"
              value={uploaderName}
              onChange={(e) => setUploaderName(e.target.value)}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Mensaje pie de foto:
            <textarea
              value={photoMessage}
              onChange={handlePhotoMessageChange} // Use the new change handler
              className="photo-message"
              placeholder="Escribí un mensaje de hasta 8 palabras (opcional)."
              rows="1"
            />
          </label>
        </div>
        <div>
          <label>
            Subir fotos (hasta 150):
            <input
              type="file"
              accept="image/*"
              multiple
              // onChange={handleFileSelection} // Use the new file selection handler
              onChange={handleFileSelection}
              ref={fileInputRef} // Attach the ref to the input
              required
            />
          </label>
        </div>

        {/* Display file processing message if files are being prepared */}
        {fileProcessing && <p className="processing-message">Procesando fotos, por favor esperá...</p>}

        {/* <button type="submit" disabled={loading || files.length === 0}>
          {loading ? "Subiendo..." : "Subir fotos"}
        </button> */}

        <button type="submit" disabled={loading || fileProcessing || files.length === 0}>
          {loading ? "Subiendo..." : "Subir fotos"}
        </button>


        {/* <button type="submit" disabled={errorMessage !== ""}>
          Subir fotos
        </button> */}


      </form>
      {}
      {uploadUrls.length > 0 && (
        <p className="success-message">
          ¡Fotos subidas exitosamente! Accedé al álbum:
          <div>
            {/* {console.log("Current Event ID:", eventId)} */}

            <a
              href={`https://snap-moments.com/album?eventId=${eventId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Acceder al álbum
            </a>
          </div>
        </p>
      )}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default PhotographUpload;
