// src/utils/fileUtils.js

import Compressor from 'compressorjs';

export const handleFileChange = (event, setFiles) => {
  const selectedFiles = Array.from(event.target.files);
  console.log("Selected files for upload:", selectedFiles); // Log the files

  if (selectedFiles.length > 150) {
    alert("Solo podés subir hasta 150 fotos.");
    event.target.value = ''; // Clear the input to reset the file selection

    return;
  }

  // Filter valid files (without size validation)
  const validFiles = selectedFiles.filter((file) => {
    if (!file.type.startsWith("image/")) {
      alert(`El archivo ${file.name} no es una imagen o extensión válida.`);
      return false;
    }
    return true;
  });
  console.log("Valid files after filtering:", validFiles); // Debugging


  if (validFiles.length > 0) {
    // Compress the images
    const compressedFiles = validFiles.map((file) => {
      return new Promise((resolve) => {
        new Compressor(file, {
          quality: 0.5, // Adjust the quality as needed
          success(result) {
            // Check if the compressed file size is within the limit
            if (result.size > 5 * 1024 * 1024) {
              alert(`La foto ${result.name} supera los 5 MB después de la compresión.`);
              resolve(null); // Return null or an appropriate value for rejected files
            } else {
              // console.log(`Compressed size of ${file.name}: ${(result.size / 1024 / 1024).toFixed(2)} MB`);
              resolve(result);
            }
          },
          error(err) {
            console.error("Compression error:", err);
            resolve(null); // Resolve with null in case of error
          }
        });
      });
    });

    Promise.all(compressedFiles).then((files) => {
      // Filter out null values (rejected files)
      console.log("Final compressed files:", files); // Debugging line


      const finalFiles = files.filter(file => file !== null);
      setFiles(finalFiles);
    });
  }
};
