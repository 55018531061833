import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import axios from "axios";
import { API_GET_EVENT_LIST } from "./endpoints";
import "./App.css";
import Header from "./Components/Header/Header";
import "./assets/styles/ListEvents.css";

const ListEvents = () => {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(API_GET_EVENT_LIST);
        setEvents(response.data); // Ensure response.data is an array
      } catch (error) {
        setError("Error fetching events");
        console.error(error);
      }
    };

    fetchEvents();
  }, []);

  const formatFirestoreDate = (timestamp) => {
    if (timestamp && timestamp._seconds) {
      return new Date(timestamp._seconds * 1000).toLocaleString();
    }
    return "N/A";
  };

  return (
    <div className="create-event-container">
       <Link to="/">
        <Header />
      </Link>
   <div className="list-events-container">
      <h2>Lista de eventos existentes</h2>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th>Album</th>
              <th>ID del evento</th>
              <th>Álbum</th>
              <th>Creado por</th>
              <th>Fecha creación</th>
              <th>URL portada</th>
            </tr>
          </thead>
          <tbody>
            {events.map((event, index) => (
              <tr key={index}>
                <td>
                  <a
                    className="table-link"
                    href={`https://snap-moments.com/album?eventId=${event.eventId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ver Album
                  </a>
                </td>
                <td>{event.eventId}</td>
                <td>{event.albumOf}</td>
                <td>{event.albumCreatedBy}</td>
                <td>{formatFirestoreDate(event.createdAt)}</td>
                <td>
                  <a
                    className="table-link"
                    href={event.coverImageUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ver Imagen de Portada
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {error && <p className="error-message">{error}</p>}
    </div>
    <footer className="support-footer">
        <p>
          Si experimentás algún inconveniente durante la creación del evento, escribinos a{' '}
          <a href="mailto:contacto@snap-moments.com">contacto@snap-moments.com</a>.
        </p>
      </footer>
    </div>
  );
};

export default ListEvents;


