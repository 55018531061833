import React from "react";
import { useNavigate, Link } from "react-router-dom";
import Header from "./Components/Header/Header";
import "../src/assets/styles/AlbumNotFound.css"
import { Helmet } from "react-helmet";

function AlbumNotFound() {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate('/'); // Navigate to the previous page in the history stack
  };
  return (
    <div className="album-not-found">
       <Helmet>
    <title>Álbum no encontrado</title>
   
  </Helmet>
       <Link to="/">
      <Header/>
      </Link>
      <h1>¡Ups... Este álbum no existe!</h1>
      <p>El álbum que estás buscando no existe o ya no está disponible.</p>
      <button onClick={handleGoBack}>Ir para Home</button>
    </div>
  );
}

export default AlbumNotFound;
