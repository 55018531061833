function FooterInstitucional() {
    return (
        <footer className="LandingPage-footer">
        {/* <div className="footer-column">
          <h3>Sobre Nosotros</h3>
          <p>Descubre más sobre nuestra misión y visión.</p>
        </div> */}
        <div className="footer-column">
          <div className="footer-links">
            <a href="/" className="footer-link">
              Home
            </a>
            <a href="/preguntas" className="footer-link">
              Preguntas Frecuentes (FAQ)
            </a>
            <a href="/templates" className="footer-link">
              Plantillas
            </a>
          </div>
          <p>
            E-mail:{" "}
            <a href="mailto:contacto@snap-moments.com">
              contacto@snap-moments.com
            </a>
            <br />
            &copy; 2024 SnapMoments. Todos los derechos reservados.
          </p>

          {/* <p>
            <a
              href="https://facebook.com/SnapMoments"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              Facebook
            </a>{" "}
            |
            <a
              href="https://instagram.com/SnapMoments"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              Instagram
            </a>
          </p> */}

          <div className="whatsapp-button">
            <a
              target="_blank"
              href="https://wa.me/5493795003578"
              className="whatsapp-link"
              rel="noreferrer"
            >
              <i class="fab fa-whatsapp"></i>
            </a>
          </div>
        </div>
      </footer>
    )
}

export default FooterInstitucional
