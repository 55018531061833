import React, { useState } from "react";
import { Link } from "react-router-dom";
import { API_DOWNLOAD_ALBUM_URL, API_DOWNLOAD_GUESTBOOK_URL } from "./endpoints";
import axios from "axios";
import Header from "./Components/Header/Header";
import "./assets/styles/DownloadAlbum.css";
import { Helmet } from "react-helmet";

//backlog palabra pase

const DownloadAlbum = () => {
  const [eventId, setEventId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isDownloading, setIsDownloading] = useState(false); // New state to handle loading
  const [downloadComplete, setDownloadComplete] = useState(false); // New state for download success
  const [isDownloadingGuestbook, setIsDownloadingGuestbook] = useState(false);

  const handleDownload = async () => {
    if (!eventId) {
      setErrorMessage("Por favor, ingresá un ID del evento.");
      return;
    }

    setIsDownloading(true); // Start loading
    setDownloadComplete(false); // Reset downloadComplete state
    setErrorMessage(""); // Clear previous error messages

    try {
      const response = await axios({
        url: `${API_DOWNLOAD_ALBUM_URL}?eventId=${eventId}`,
        method: "GET",
        responseType: "blob", // important to handle the zip file as binary
      });

      // Create a URL for the file and download it
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `album-${eventId}.zip`); // File name for the downloaded zip
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setDownloadComplete(true); // Set success message
    } catch (error) {
      console.error("Error downloading album or not found.");
      if (error.response && error.response.status === 404) {
        setErrorMessage("Álbum no encontrado.");
        console.info("No album found");
      } else {
        setErrorMessage(
          "Hubo un error al descargar el álbum. Por favor, intentá nuevamente."
        );
      }
    } finally {
      setIsDownloading(false); // Stop loading regardless of success/failure
    }
  };

   // Function to download guestbook
   const handleDownloadGuestbook = async () => {
    if (!eventId) {
      setErrorMessage("Por favor, ingresá un ID del evento.");
      return;
    }

    // setIsDownloading(true);
    setIsDownloadingGuestbook(true);

    setDownloadComplete(false);
    setErrorMessage("");

    try {
      const response = await axios({
        url: API_DOWNLOAD_GUESTBOOK_URL,
        method: "POST",
        data: { eventId },  // Send eventId in the request body
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `guestbook-${eventId}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setDownloadComplete(true);
    } catch (error) {
      console.error("Error downloading guestbook or not found.");
      setErrorMessage("Hubo un error al descargar el libro de mensajes. Por favor, intentá nuevamente.");
    } finally {
      setIsDownloadingGuestbook(false);
    }
  };

  return (
    <div className="download-album-container">
      <Helmet>
        <title>Snap Moments Descarga de Álbum</title>
      </Helmet>
      <Link to="/">
        <Header />
      </Link>
      <h2>Descargá tu álbum</h2>
      <p className="title">Ingresá el ID de tu evento:</p>
      <p className="subtitle">El ID del evento se encuentra al final del enlace de tu álbum, justo después de <strong>"eventId="</strong>. Por ejemplo, en el enlace <strong>snap-moments.com/album?eventId=mi-fiesta</strong>, el ID del evento es "mi-fiesta".</p>
      <input
        type="text"
        value={eventId}
        onChange={(e) => setEventId(e.target.value)}
        placeholder="ID del evento"
        required
      />




      <button onClick={handleDownload} disabled={isDownloading}>
        {isDownloading ? "Procesando..." : "Descargar álbum"}
      </button>


      <button onClick={handleDownloadGuestbook} className="download-guestbook" disabled={isDownloadingGuestbook}>
        {isDownloadingGuestbook  ? "Procesando..." : "Descargar libro de mensajes"}
      </button>

      {isDownloading && (
        <p>
          El tiempo de descarga puede variar según el tamaño del álbum. Por
          favor, aguardá unos minutos...
        </p>
      )}{" "}
      {/* Show this while downloading */}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {downloadComplete && (
        <p className="success-message">
          ¡El álbum ha sido descargado con éxito!
        </p>
      )}{" "}
      {/* Show this after successful download */}
      {/* New text about downloading from a computer */}
      <p className="recommendation">
        <strong>Importante:</strong> Te sugerimos hacer la{" "}
        <strong>descarga desde una computadora</strong> en lugar de un
        dispositivo móvil puede evitar problemas de almacenamiento y
        compatibilidad.
        <br />
        <br />
        La carpeta del álbum se descarga en <strong>formato ZIP</strong> y puede
        tener un <strong>tamaño considerable</strong>.
      </p>
      {/* Support message */}
      <footer className="support-footer">
        <p>
          Si experimentás algún inconveniente durante la descarga, escribinos a{" "}
          <a href="mailto:contacto@snap-moments.com">
            contacto@snap-moments.com
          </a>{" "}
          mencionando el nombre de tu album.
        </p>
      </footer>
    </div>
  );
};

export default DownloadAlbum;
