import { React, useState } from "react";
import { Link } from "react-router-dom";
import {CARTELES_CANVA, CARTELES_CANVA_2, INVITACION_CUADRADO, INVITACION_VERTICAL, POCKET_QR} from "./CanvaEndpoints"
import "./assets/styles/TemplatePage.css"; // Create a separate CSS file for styling
import logo from "./assets/images/logoSnapOk.png";
import templateImage1 from "./assets/images/invitaciones.png"; // Replace with actual image paths
import templateImage2 from "./assets/images/carteles.png";
import templateImage3 from "./assets/images/pocket.png";
import { Helmet } from "react-helmet-async";
import "./assets/styles/LandingPage.css";
import FooterInstitucional from "./Components/FooterInstitucional/FooterInstitucional";

function TemplatePage() {
  const handleLinkClick = (e, url) => {
    const enteredPassword = window.prompt("Por favor, ingresa la contraseña:");
    if (enteredPassword !== "admin") {
      e.preventDefault();
      alert("Contraseña incorrecta. Intenta nuevamente.");
    }
  };
  return (
    <div className="TemplatePage">
      <Helmet>
        <title>Plantillas para Eventos - SnapMoments</title>
      </Helmet>
      <header className="TemplatePage-header">
        <Link to="/">
          <img src={logo} alt="Logo" className="LandingPage-logo" />
        </Link>
      </header>

     
        <div className="TemplatePage-content">
          <h2>
            Personalizá tus eventos con plantillas (templates) listas para
            imprimir
          </h2>
          <section className="TemplatePage-section">
            <p>
              ¡Con cada álbum, tenés acceso a plantillas de Canva como bonus
              para editar e imprimir!
            </p>
          </section>

          <h2>Modelos de Plantillas</h2>

          <section className="TemplatePage-section template-gallery">
            <div className="template-item">
              <img
                src={templateImage2}
                alt="Plantilla 2"
                className="template-image"
              />
              <h3>Carteles</h3>
              <p>Ideales para la entrada de tu ceremonia o evento.</p>
              <div className="model-canvas">
                <a
                  href={CARTELES_CANVA}
                  target="_blank"
                  className="download-link"
                  rel="noopener noreferrer"
                  onClick={(e) =>
                    handleLinkClick(
                      e,
                      {CARTELES_CANVA}
                    )
                  }
                >
                  Cartel vertical 1
                </a>
              </div>
              <div className="model-canvas">
                <a
                  href={CARTELES_CANVA_2}
                  target="_blank"
                  className="download-link"
                  rel="noopener noreferrer"
                  onClick={(e) =>
                    handleLinkClick(
                      e,
                      {CARTELES_CANVA_2}
                    )
                  }
                >
                  Cartel vertical 2
                </a>
              </div>
            </div>
            <div className="template-item">
              <img
                src={templateImage1}
                alt="Plantilla 1"
                className="template-image"
              />
              <h3>Tarjetas</h3>
              <p>Elegí entre varios diseños personalizados.</p>
              <div className="model-canvas">
                <a
                  href={INVITACION_CUADRADO}
                  target="_blank"
                  className="download-link"
                  rel="noopener noreferrer"
                  onClick={(e) =>
                    handleLinkClick(
                      e,
                      {INVITACION_CUADRADO}
                    )
                  }
                >
                  Invitación mesa cuadrado
                </a>
              </div>
              <div className="model-canvas">
                {" "}
                <a
                  href={INVITACION_VERTICAL}
                  target="_blank"
                  className="download-link"
                  rel="noopener noreferrer"
                  onClick={(e) =>
                    handleLinkClick(
                      e,
                      {INVITACION_VERTICAL}
                    )
                  }
                >
                  {" "}
                  Invitación mesa vertical
                </a>
              </div>
            </div>

            <div className="template-item">
              <img
                src={templateImage3}
                alt="Plantilla 3"
                className="template-image"
              />
              <h3>Pocket QR</h3>
              <p>
                Souvenir para que cada invitado se lleve el código QR de tu
                galería.
              </p>
              <div className="model-canvas">
                <a
                  href={POCKET_QR}
                  target="_blank"
                  className="download-link"
                  rel="noopener noreferrer"
                  onClick={(e) =>
                    handleLinkClick(
                      e,
                      {POCKET_QR}
                    )
                  }
                >
                  Pocket QR
                </a>
              </div>
            </div>
          </section>

          <h2>Formas divertidas de recopilar fotos usando Snap Moments</h2>

          <section className="TemplatePage-section template-gallery">
            <div className="use-cases slideshow">
              <h3>Presentación de fotos</h3>
              <p>
                Activá la función de presentación de fotos y disfrutá de cómo se
                proyectan automáticamente las imágenes de los invitados. ¡Es una manera espectacular de resaltar tu
                evento desde todos los ángulos!
              </p>

            </div>

            <div className="use-cases mc-dj">
              <h3>¡Involucrá a tu DJ!</h3>
              <p>
                ¿Qué mejor manera de anunciar la recopilación de fotos que con
                tu MC/DJ? Pedile que presente tu álbum durante la bienvenida, ¡y los invitados empezarán a agregar
                sus mejores fotos en un abrir y cerrar de ojos!
              </p>
            </div>

            <div className="use-cases cabina">
              <h3>¡Tu propia cabina de fotos!</h3>
              <p>
                Designá un área para las fotos, reuní
                accesorios y exhibí tu cartel con el código QR. Los invitados
                escanean y suben esos increibles momentos a tu galería de Snap Moments.
                ¡Divertidísimo!
              </p>
            </div>
          </section>
        </div>
     
<FooterInstitucional/>
    
    </div>
  );
}

export default TemplatePage;
