import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './LandingPage';
import AlbumPage from './AlbumPage'; // This is your AlbumPage component
import AlbumNotFound from "./AlbumNotFound";
import DownloadAlbum from "./DownloadAlbum";
import CreateEvent from "./CreateEvent";
import ListEvents from './ListEvents';
import Admin from './Admin';
import Preguntas from "./Preguntas"
import TemplatePage from './Templates';
import { HelmetProvider } from 'react-helmet-async';
import PhotographUpload from './PhotographUpload';


function App() {
  return (
    <HelmetProvider>

    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/preguntas" element={<Preguntas />} />
      <Route path="/templates" element={<TemplatePage />} />
      <Route path="/portal-fotografos" element={<PhotographUpload />} />
      {/* Define the album route */}
      <Route path="/album" element={<AlbumPage />} />
      <Route path="/album/album-not-found" element={<AlbumNotFound />} />
      <Route path="/album/download-album" element={<DownloadAlbum />} /> {/* New Download Album Page */}
      <Route path="/admin" element={<Admin />} />

      <Route path="/admin/create" element={<CreateEvent/>} /> {/* Create new Album */}
      <Route path="/admin/list-events" element={<ListEvents/>} /> {/* Create new Album */}
      <Route path="*" element={<Navigate to="/" />} /> {/* Redirect any unspecified route to LandingPage */}
    </Routes>
    </HelmetProvider>

  );
}

export default App;
