import React from "react";
import { Link } from "react-router-dom";
import logo from "./assets/images/logoSnapOk.png";
import "./assets/styles/Preguntas.css";
import "./assets/styles/LandingPage.css";
import { Helmet } from "react-helmet";
import FooterInstitucional from "./Components/FooterInstitucional/FooterInstitucional";
function Preguntas() {

  return (
    <div className="PreguntasPage">
          <Helmet>
        <title>Snap Moments Preguntas Frecuentes</title>
      </Helmet>
      <header className="LandingPage-header">
      <Link to="/">
        <img src={logo} alt="Logo" className="LandingPage-logo" />
        </Link>
      </header>
      <div className="PreguntasPage-content">
        <h1>Preguntas Frecuentes</h1>

        <section className="PreguntasPage-section">
          <h2>¿Cómo funciona Snap Moments? </h2>
          <p>
            Snap Moments es un álbum digital pensado para simplificar la
            recopilación y captura de fotos de tu evento. Los invitados pueden
            compartir su contenido escaneando un código QR, sin apps ni
            registros. ¡Así, ellos disfrutan y vos tenés todos los recuerdos en
            un solo lugar!
          </p>
        </section>

        <section className="PreguntasPage-section">
          <h2>
            ¿Cómo pueden los invitados subir fotos o videos a mi álbum digital?
          </h2>
          <p>
            Hay dos formas de subir contenido: escaneando el código QR o
            accediendo a través del enlace personalizado (URL). Desde allí, los
            invitados pueden optar por cargar fotos desde su teléfono o usar
            la cámara de su dispositivo para tomar fotos en el momento y
            subirlas.
          </p>
        </section>
        <section className="PreguntasPage-section">
          <h2>¿Hay un límite de fotos que puedo subir?</h2>
          <p>No, no hay límite. ¡Podés subir todas las fotos que quieras!</p>
        </section>

        <section className="PreguntasPage-section">
          <h2>¿Puedo descargar las fotos del álbum?</h2>
          <p>
            Sí, solo tenés que hacer clic en la foto que deseás y seleccionar el
            ícono de descarga. Únicamente el dueño de la fiesta puede descargar
            el álbum completo (casi) con un sólo clic.
          </p>
        </section>

        <section className="PreguntasPage-section">
          <h2>¿Qué hago si subí una foto por error?</h2>
          <p>
            Si subiste una foto por error, podés eliminarla fácilmente desde el
            álbum con un clic en el botón de <i className="fas fa-trash"></i> . 
            Si no sos vos quien subió la foto, vas a necesitar un PIN que
            es entregado al dueño del evento.
          </p>
        </section>

        <section className="PreguntasPage-section">
          <h2>¿Cuánto tiempo estará disponible mi álbum online?</h2>
          <p>
          ¡Tu álbum estará disponible hasta por 3 meses desde la fecha de su
            creación para que sigas reviviendo esos momentos inolvidables!
          </p>
        </section>

        <section className="PreguntasPage-section">
          <h2>¿Puedo proyectar las fotos en el evento en vivo?</h2>
          <p>
            ¡Sí! Usá la función de reproducción automática para proyectar las
            fotos del álbum durante el evento.
          </p>
        </section>

        <section className="PreguntasPage-section">
          <h2>¿Tienen control parental para filtrar contenido inapropiado?</h2>
          <p>
            ¡Sí! contamos con control parental respaldado por la tecnología de
            IA de Google, que detecta y bloquea automáticamente fotos subidas de
            tono.
          </p>
        </section>

        {/* Add more FAQs as needed */}
      </div>
<FooterInstitucional/>
    
    </div>
  );
}

export default Preguntas;
