export const API_UPLOAD_URL =
  "https://us-central1-moonlit-sphinx-400613.cloudfunctions.net/getUploadUrl";

export const API_LIST_URL =
  "https://us-central1-moonlit-sphinx-400613.cloudfunctions.net/listPhotos";

export const API_EVENT_DETAILS_URL =
  "https://us-central1-moonlit-sphinx-400613.cloudfunctions.net/getdatafromevent";

export const API_SAFE_SEARCH_URL =
  "https://us-central1-moonlit-sphinx-400613.cloudfunctions.net/safesearch";

export const API_HEART =
  "https://us-central1-moonlit-sphinx-400613.cloudfunctions.net/heart";

export const API_CREATE_EVENT_URL =
  "https://us-central1-moonlit-sphinx-400613.cloudfunctions.net/adddatatoevent";

  export const API_DELETE_PHOTO =
  "https://us-central1-moonlit-sphinx-400613.cloudfunctions.net/deletephotofromfirestore";

export const API_DOWNLOAD_ALBUM_URL =
  "https://us-central1-moonlit-sphinx-400613.cloudfunctions.net/downloadallfunctions"; // Your Firebase function URL for downloading the zip

  export const API_GET_EVENT_LIST =
  "https://us-central1-moonlit-sphinx-400613.cloudfunctions.net/geteventlist";

  export const API_POST_PHOTOGRAPHER =
  "https://us-central1-moonlit-sphinx-400613.cloudfunctions.net/upload-direct-to-firestore";

  export const API_GET_CHECK_IF_EVENT_EXISTS =
  "https://us-central1-moonlit-sphinx-400613.cloudfunctions.net/checkeventexistsincollection";

  export const API_DOWNLOAD_GUESTBOOK_URL =
  "https://us-central1-moonlit-sphinx-400613.cloudfunctions.net/guestbookdownload";