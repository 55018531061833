import React from 'react';

const PhotoMessageInput = ({ value, onChange, errorMessage }) => {

  // Function to handle the emoji click
  

  return (
    <div className="photo-message-container">
      <textarea
        className="photo-message"
        value={value}
        placeholder="Escribí un mensaje de hasta 8 palabras (opcional)."
        onChange={onChange}
        rows="1"
      />
      
    </div>
  );
};

export default PhotoMessageInput;
