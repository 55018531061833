
import React from 'react';
import logo from '../../assets/images/logoSnapOk.png';

const Header = () => (
  <header className="App-header">
    <img src={logo} alt="Logo" className="App-logo" />
  </header>
);

export default Header;
