import React, { useState, useEffect, useCallback } from 'react';
import "./ScrollToTop.css";

const ScrollToTopButton = ({ hasPhotos }) => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = useCallback(() => {
    const scrollY = window.scrollY;
    const documentHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;
    const triggerPoint = documentHeight * 0.55 - windowHeight;

    if (hasPhotos && !showScroll && scrollY > triggerPoint) {
      setShowScroll(true);
    } else if (showScroll && (scrollY <= triggerPoint || !hasPhotos)) {
      setShowScroll(false);
    }
  }, [showScroll, hasPhotos]);

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => window.removeEventListener('scroll', checkScrollTop);
  }, [checkScrollTop]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button
      onClick={scrollToTop}
      className={`scrollToTop ${showScroll ? 'show' : ''}`}
     
    >
      <i className="fas fa-chevron-up"></i>
    </button>
  );
};

export default ScrollToTopButton;
